<template>
    <div>
        <div class="dark_ver">
            <svg class="my-icon" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="9.5" width="19" height="1" rx="0.5" stroke="#003D45"/>
                <rect x="9.5" y="19.5" width="19" height="1" rx="0.5" transform="rotate(-90 9.5 19.5)" stroke="#003D45"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scope>
</style>